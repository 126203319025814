@import url('https://api.fontshare.com/v2/css?f[]=satoshi@700,300,401,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .custom-scrollbar ::-webkit-scrollbar {
    width: 10px;
  }
  


  ::-webkit-scrollbar {
    width: 5px;
  }
  

::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
::-webkit-scrollbar-thumb {
    background: #a1a2e9; 
  }
  
::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }



  .custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }
  
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #a1a2e9;
  }
  
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .alert_wrap {
    animation-duration: 10s;
    animation-timing-function: cubic-bezier(0, 1.03, 0.69, 1.8);
    animation-delay: 1.5s;
    animation-iteration-count: 5;
    animation-direction: normal;
    animation-play-state: running;
    animation-name: arrows_animation;
    transform: translateX(0%);
  }
  

.example-track {
  top: 5px;
  height: 6px;
  border-radius: 10px;
}
.example-track.example-track-1 {
  background: #FF780C;;
}
.example-track.example-track-0 {
  background: rgb(221, 218, 218);
}
.example-track.example-track-2 {
  background: rgb(221, 218, 218);;
}

.example-thumb {
  top: -2px;
  font-size: 0.9em;
  text-align: center;
  background-color: #FF780C;
  color: white;
  cursor: pointer;
  /* border: 5px solid gray; */
  border-radius: 50%;
  padding: 9px;
  box-sizing: border-box;
}